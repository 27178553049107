import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#000000',
        secondary: '#eceff1'
      }
    }
  }
}

export default new Vuetify(opts)
