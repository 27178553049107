import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/router/auth'

Vue.use(Router)

function checkSession (to, from, next) {
  if (Vue.prototype.$session.exists()) {
    auth.check()
      .then(res => {
        if (res) {
          next()
        } else {
          next('/login')
        }
      })
  }
  else {
    next('/login')
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      component: () => import(
        '@/pages/errors/404.vue'
      )
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(`@/pages/Login.vue`),
      beforeEnter (to, from, next) {
        if (Vue.prototype.$session.exists()) {
          auth.check()
            .then(res => {
              if (res) {
                next('/home')
              } else {
                next()
              }
            })
        }
        else {
          next()
        }
      }
    },
    {
      path: '/recover/password/:hash',
      name: 'RecoverPassword',
      component: () => import(`@/pages/RecoverPassword.vue`),
      beforeEnter (to, from, next) {
        next()
      }
    },
    {
      path: '/',
      name: 'Root',
      redirect: {
        name: 'Home'
      },
      component: () => import(`@/pages/Root.vue`),
      children: [
        {
          path: '/home',
          name: 'Home',
          component: () => import(`@/pages/Home.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/users',
          name: 'User',
          component: () => import(`@/pages/User.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/companies',
          name: 'Company',
          component: () => import(`@/pages/Company.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/catalogues',
          name: 'Catalogue',
          component: () => import(`@/pages/Catalogue.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/campaigns',
          name: 'Campaign',
          component: () => import(`@/pages/Campaign.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/campaigns/:id/messages',
          name: 'Message',
          component: () => import(`@/pages/Message.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/settings/providers',
          name: 'Provider',
          component: () => import(`@/pages/Provider.vue`),
          beforeEnter: checkSession
        },
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  next();
});

router.afterEach((to, from) => {
});

export default router
